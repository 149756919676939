import styled from '@emotion/styled';
import { format } from 'date-fns';
import { default as esLocale } from 'date-fns/locale/es';
import React, { useEffect } from "react";
import ReactTooltip from 'react-tooltip';
import info_icon from '../images/iconmonstr-info-thin.svg';


export const StyledSection = styled.div`
  margin-top: 1rem;
  margin-bottom: 2rem;
  font-family: 'Noto Sans Mono', monospace;
  background: #fff;
  border-radius: 5px 5px 2px 2px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

  .no-gutters {
    margin-right: 0;
    margin-left: 0;

    > .col,
    > [class*="col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .section-header {
    display: flex;
      border-radius: 5px;
    justify-content: space-between;
    align-items: center;
    background: #cad3d7;
    padding: 1.5rem;
  }

  .section-header h3 {
    font-weight: 400;
    font-size: 18px;
    margin: 0;
      color: #323d53;
  }

  .section-meta {
    font-size: 14px;
    color: #475055;
    min-width: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .section-time-range {
    margin: 0 0.5rem;
    text-transform: capitalize;
  }
  .section-meta img {
    height: 20px;
    width: 20px;
  }
  .section-children {
    background: #f4f4f5;
  }
`;

const formatTimeRange = (timerange) => {
  if (timerange === 'mes') {
    const today = new Date(Date.now());
    return format(today, 'MMMM yyyy', {
      locale: esLocale
    });
  }
  return timerange;
};

export const Section =
  ({
     id,
     children,
     title,
     year,
     level,
     delimitation,
     information
   }) => {
    useEffect(() => {
      ReactTooltip.rebuild();
    }, [information]);
    return (
      <StyledSection>
        <div className='section-header'>
          <div className='section-title'>
            <h3>{title}</h3>
          </div>
          <div className='section-meta'>
            {
              information
                ? <img alt='Source Information' data-tip data-for={id} src={info_icon} />
                : null
            }
            <span className='section-time-range'>| {formatTimeRange(information?.temporalidad)} |</span>
            <span className='section-lvl'>{delimitation}</span>
          </div>
        </div>
        <div className='section-children'>
          {children}
        </div>
        {
          information
            ? <ReactTooltip id={id} aria-haspopup='true' >
              <div style={{ width: 400 }}>
                <p>Fuente: {information?.source}</p>
                <p>{information?.caption}</p>
              </div>
            </ReactTooltip>
            : null
        }
      </StyledSection>
    )
  }

Section.defaultProps = {
  title: '',

}

