import styled from "@emotion/styled";
import { sankey, sankeyLeft, sankeyLinkHorizontal } from "d3-sankey";
import { scaleOrdinal } from "d3-scale";
import React from 'react';
import { withSize } from 'react-sizeme';
import { arrColors } from "../helpers/colors";

const NodeLabel = styled.div`
  font-size: 12px;
  pointer-events: none;
  height: 100%;
  display: flex;
  flex-direction: column;
    padding: 0.25rem;
  color: #fff;
    span {
        text-transform: capitalize;
    }
`;

const NodeTooltip = styled.div`
    width: 100%;
    min-height: 50px;
    color: #323d53;
    background: #cad3d7;
    text-transform: capitalize;
    padding: 0.25rem;
    font-size: 12px;
    display: flex;
    font-weight: bold;
    flex-direction: column;
`;

export class Sankey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: null,
      highlighted: []
    }
  }

  getLayout = () => {
    const { size: { width }} = this.props;
    const { height } = this.props;
    return sankey()
      .nodeId(d => d.name)
      .nodeAlign(sankeyLeft)
      .nodeWidth(150)
      .nodePadding(2)
      .extent([[0, 5], [width, height - 5]])
  }

  getColorScale = (nodes) => {
    return scaleOrdinal()
      .domain(nodes.map(d => d.name))
      .range(arrColors);
  }

  renderNodes = (node) => {
    const colorScale = this.colorScale;
    const { x0, y0, y1, x1} = node;
    return (
      <rect
        key={node.index}
        x={x0 + 1}
        y={y0}
        height={y1 - y0}
        width={x1 - x0 - 2}
        fill={colorScale(node.name)}
        stroke='#fff'
      >
        <title>{`${node.name}\n${node.value.toLocaleString()}`}</title>
      </rect>
    )
  }

  renderNodeLabels = (node) => {
    const { formatValueFn } = this.props;
    const { x0, y0, y1, x1} = node;
    return (
      <foreignObject
        key={node.index}
        onMouseEnter={e => this.handleNodeMouseEnter(e, node)}
        onMouseLeave={e => this.handleNodeMouseLeave(e)}
        x={x0 + 1}
        y={y0}
        height={y1 - y0}
        width={x1 - x0 - 2}
      >
        <NodeLabel>
          <span style={{ display: 'block' }}>{node.name}</span>
          <span style={{ display: 'block' }}>{formatValueFn(node.value)}</span>
        </NodeLabel>

      </foreignObject>
    )
  }

  handleNodeMouseEnter = (e, node) => {
    const to = node.sourceLinks.map(d => d.index);
    const from = node.targetLinks.map(d => d.index);
    const highlighted = [...to, ...from];
    this.setState({
      current: node,
      highlighted
    });
  }

  handleNodeMouseLeave = (e) => {
    this.setState({
      current: null,
      highlighted: []
    })
  }

  renderLinkLabel = (link) => {
    return null;
  }

  renderLinks = (link) => {
    const { current, highlighted } = this.state;
    const colorScale = this.colorScale;
    return (
      <g style={{ mixBlendMode: 'multiply' }} key={link.index}>
        <path
          style={{ transition: 'stroke-opacity 0.25s ease' }}
          stroke={colorScale(link.source.name)}
          fill='none'
          d={sankeyLinkHorizontal()(link)}
          strokeOpacity={current ? highlighted.includes(link.index) ? '1' : '0.2' : '1'}
          strokeWidth={Math.max(1, link.width)}
        >
          <title>{`${link.source.name} → ${link.target.name}\n${link.value.toLocaleString()}`}</title>
        </path>
      </g>
    )
  }

  render () {
    const { size: { width }} = this.props;
    const { height, data, formatValueFn } = this.props;
    const { current } = this.state;
    const { nodes, links } = this.getLayout()(data);
    this.colorScale = this.getColorScale(nodes);
    return (
      <div>
          <svg width={width} height={height}>
              <g>{nodes.map(this.renderNodes)}</g>
              <g>{links.map(this.renderLinks)}</g>
              <g>{links.map(this.renderLinkLabel)}</g>
              <g>{nodes.map(this.renderNodeLabels)}</g>
          </svg>
          <div style={{ height: 50, background: '#cad3d7' }}>
              {current
                  ? <NodeTooltip>
                      <span>{current.name}</span>
                      <span>{formatValueFn(current.value)}</span>
                  </NodeTooltip>
                  : null
              }
          </div>
      </div>
    )
  }
}

Sankey.defaultProps = {
  size: {
    width: 0,
    height: 0
  },
  width: 1000,
  height: 1000,
  formatValueFn: n => n.toLocaleString()
};

export default withSize()(Sankey);
