import { groups } from "d3-array";
import React from 'react';
import BoxPlot from "../../components/BoxPlot";
import { withDataFetch } from "../../components/withDataFetch";

const FetchWrapper = withDataFetch(BoxPlot);

const commonQuery = {
  select_filter: 'sinco2,value',
  schema: 'public',
  table: 'enoe_top_sinco',
  cve_ent: '00',
  orderdesc: 'vacantes'
}

const ageKeys = [
  '15-24',
  '25-35',
  '36-45',
  '46-55',
  '56-65'
]

export const Age = ({ data }) => {
  return (
    <FetchWrapper
      boxKeys={ageKeys}
      colors={{
        '15-24': '#64d9d5',
        '25-35': '#328079',
        '36-45': '#c49955',
        '46-55': '#1d4134',
        '56-65': '#e4d0a8'
      }}
      queries={[
        {
          url: 'data_to_filters',
          query: {
            categories: 'sincos'
          }
        },
        {
          url: '/group_filter',
          query: {
            ...commonQuery,
            indicator: 'shdage_strata2'
          }
        },
        {
          url: '/group_filter',
          query: {
            ...commonQuery,
            indicator: 'shdage_strata3'
          }
        },
        {
          url: '/group_filter',
          query: {
            ...commonQuery,
            indicator: 'shdage_strata4'
          }
        },
        {
          url: '/group_filter',
          query: {
            ...commonQuery,
            indicator: 'shdage_strata5'
          }
        },
        {
          url: '/group_filter',
          query: {
            ...commonQuery,
            indicator: 'shdage_strata6'
          }
        },
      ]}
      formatDataFn={(data) => {
        const [sincos, ...indicators] = data;
        const sincosElements = sincos[0].sincos;
        const sincosMap = new Map();
        sincosElements.forEach((element) => {
          sincosMap.set(element.value, element.label.trim());
        });

        const joined = indicators
          .map((indicator, index) => {
            return indicator.map((d) => {
              return {
                education: ageKeys[index],
                label: sincosMap.get(d.sinco2.toString()),
                value: d.value
              };
            });
          })
          .reduce((acc, val) => [...acc, ...val], [])

        const grouped = groups(joined, d => d.label)
          .map((profession) => {
            const [label, properties] = profession;
            const obj = {};
            properties.forEach((property) => {
              obj[property.education] = property.value / 100;
            });
            return {
              label,
              ...obj
            };
          })
        return grouped;
      }}
    />
  );
}
