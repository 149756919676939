import React from 'react';
import BoxPlot from "../../../components/BoxPlot";
import { withDataFetch } from "../../../components/withDataFetch";
import { toBoxPlot } from "../../../helpers/dataTransformations";

const FetchWrapper = withDataFetch(BoxPlot);

const commonQuery = {
  select_filter: 'sinco2,value',
  schema: 'public',
  table: 'enoe_top_sinco',
  orderdesc: 'vacantes'
}

const ageKeys = [
  '15-24',
  '25-35',
  '36-45',
  '46-55',
  '56-65'
]

export const Age = ({ data, cve_cda= '09' }) => {
  return (
    <FetchWrapper
      boxKeys={ageKeys}
      colors={{
        '15-24': '#64d9d5',
        '25-35': '#328079',
        '36-45': '#c49955',
        '46-55': '#1d4134',
        '56-65': '#e4d0a8'
      }}
      queries={[
        {
          url: 'data_to_filters',
          query: {
            categories: 'sincos'
          }
        },
        {
          url: '/group_filter',
          query: {
            ...commonQuery,
            cve_cda,
            indicator: 'shdage_strata2'
          }
        },
        {
          url: '/group_filter',
          query: {
            ...commonQuery,
            cve_cda,
            indicator: 'shdage_strata3'
          }
        },
        {
          url: '/group_filter',
          query: {
            ...commonQuery,
            cve_cda,
            indicator: 'shdage_strata4'
          }
        },
        {
          url: '/group_filter',
          query: {
            ...commonQuery,
            cve_cda,
            indicator: 'shdage_strata5'
          }
        },
        {
          url: '/group_filter',
          query: {
            ...commonQuery,
            cve_cda,
            indicator: 'shdage_strata6'
          }
        },
      ]}
      formatDataFn={(data) => {
        return toBoxPlot(data, ageKeys);
      }}
    />
  );
}
