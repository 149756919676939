import React from 'react';
import BoxPlot from "../../../components/BoxPlot";
import { withDataFetch } from "../../../components/withDataFetch";
import { toBoxPlot } from "../../../helpers/dataTransformations";

const FetchWrapper = withDataFetch(BoxPlot);

const commonQuery = {
  select_filter: 'sinco2,value',
  schema: 'public',
  table: 'enoe_top_sinco',
  orderdesc: 'vacantes'
};

const workKeys = [
  'Tiempo Completo',
  'Tiempo Parcial'
]

export const WorkingDay = ({ data, cve_cda= '09' }) => {
  return (
    <FetchWrapper
      boxKeys={workKeys}
      colors={{
        'Tiempo Completo': '#64d9d5',
        'Tiempo Parcial': '#328079',
      }}
      queries={[
        {
          url: 'data_to_filters',
          query: {
            categories: 'sincos'
          }
        },
        {
          url: '/group_filter',
          query: {
            ...commonQuery,
            cve_cda,
            indicator: 'shfull_time'
          }
        },
        {
          url: '/group_filter',
          query: {
            ...commonQuery,
            cve_cda,
            indicator: 'shpart_time'
          }
        },
      ]}
      formatDataFn={(data) => {
        return toBoxPlot(data, workKeys);
      }}
    />
  );
}
