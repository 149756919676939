import React from 'react';
import BoxPlot from "../../../components/BoxPlot";
import { withDataFetch } from "../../../components/withDataFetch";
import { toBoxPlot } from "../../../helpers/dataTransformations";

const FetchWrapper = withDataFetch(BoxPlot);

const commonQuery = {
  select_filter: 'sinco2,value',
  schema: 'public',
  table: 'enoe_top_sinco',
  orderdesc: 'vacantes'
}

const genderKeys = [
  'Mujeres',
  'Hombres'
]

export const Gender = ({ data, cve_cda= '09' }) => {
  return (
    <FetchWrapper
      boxKeys={genderKeys}
      colors={{
        'Mujeres': '#64d9d5',
        'Hombres': '#328079',
      }}
      queries={[
        {
          url: 'data_to_filters',
          query: {
            categories: 'sincos'
          }
        },
        {
          url: '/group_filter',
          query: {
            ...commonQuery,
            cve_cda,
            indicator: 'sh_mujer'
          }
        },
        {
          url: '/group_filter',
          query: {
            ...commonQuery,
            cve_cda,
            indicator: 'sh_hombre'
          }
        },
      ]}
      formatDataFn={(data) => {
        return toBoxPlot(data, genderKeys);
      }}
    />
  );
}
