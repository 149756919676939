import React from 'react';
import BoxPlot from '../../../components/BoxPlot';
import { withDataFetch } from "../../../components/withDataFetch";
import { toBoxPlot } from "../../../helpers/dataTransformations";

const FetchWrapper = withDataFetch(BoxPlot);

const commonQuery = {
  select_filter: 'sinco2,value',
  schema: 'public',
  table: 'enoe_top_sinco',
  orderdesc: 'vacantes'
}

const educationKeys = [
  'Educación Básica',
  'Educación Media',
  'Educación Técnica',
  'Educación Superior',
  'No Definido'
]

export const EducationLevel = ({ data, cve_cda= '09' }) => {
  return (
    <FetchWrapper
      boxKeys={educationKeys}
      colors={{
        'Educación Básica': '#64d9d5',
        'No Definido': '#328079',
        'Educación Técnica': '#c49955',
        'Educación Media': '#1d4134',
        'Educación Superior': '#e4d0a8'
      }}
      queries={[
        {
          url: 'data_to_filters',
          query: {
            categories: 'sincos'
          }
        },
        {
          url: '/group_filter',
          query: {
            ...commonQuery,
            cve_cda,
            indicator: 'shdniv_ins31'
          }
        },
        {
          url: '/group_filter',
          query: {
            ...commonQuery,
            cve_cda,
            indicator: 'shdniv_ins32'
          }
        },
        {
          url: '/group_filter',
          query: {
            ...commonQuery,
            cve_cda,
            indicator: 'shdniv_ins33'
          }
        },
        {
          url: '/group_filter',
          query: {
            ...commonQuery,
            cve_cda,
            indicator: 'shdniv_ins34'
          }
        },
        {
          url: '/group_filter',
          query: {
            ...commonQuery,
            cve_cda,
            indicator: 'shdniv_ins35'
          }
        },
      ]}
      formatDataFn={(data) => {
        return toBoxPlot(data, educationKeys);
      }}
    />
  );
}
