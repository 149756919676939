export const arrColors = [
  '#64d9d5',
  '#328079',
  '#c49955',
  '#1d4134',
  '#e4d0a8',
  '#a19e9f',
  '#777674',
  '#712130',
  '#69b47a',
  '#809bda'
];

export const colors = {
  'Educacion Basica': '#64d9d5',
  'Educacion Media': '#328079',
  'Educacion Tecnica': '#c49955',
  'Educacion Superior': '#e4d0a8',
  'No Definido': '#a19e9f',
  'Those Days': '#777674',
  'Memories on the wall': '#69b47a',
  'Places where I': '#5a80d0',
  'Across the blue': '#ffb1a8',
  'How it all changed': '#809bda'
};

export const spanishColorToRGB = (color) => {
  switch (color) {
    case 'amarillo': return '#e4d0a8';
    case 'naranja': return '#c49955';
    case 'verde': return '#328079';
    case 'rojo': return '#712130';
  }
  return '#712130';
};
