import styled from "@emotion/styled";
import { extent } from "d3-array";
import { format } from "d3-format";
import { scaleLinear } from "d3-scale";
import React from 'react';
import { withSize } from "react-sizeme";

const Tooltip = styled.div`
  font-size: 10px;
  line-height: 12px;
  background: #323d53;
  color: #fff;
  padding: 0.25rem;
  border-radius: 3px;
  font-weight: 500;
  span {
    display: block;
  }
`

export class Scatter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: null
    }
  }

  get xScale () {
    const { data: { elements } } = this.props;
    const width = this.width;
    const domain = extent(elements, d => d.x)
    return scaleLinear()
      .domain(domain)
      .range([0, width]);
  }

  get yScale () {
    const { data: { elements } } = this.props;
    const height = this.height;
    const domain = extent(elements, d => d.y);
    return scaleLinear()
      .domain(domain)
      .range([height, 0]);
  }

  get width () {
    const { size: { width }, margin } = this.props;
    return  width - margin.left - margin.right;
  }

  get height () {
    const { height, margin } = this.props;
    return height - margin.top - margin.bottom;
  }

  handleMouseEnter = (e, datapoint) => {
    this.setState({ current: datapoint });
  }

  getCircleFill = (datapoint) => {
    const { quadrant } = datapoint;
    if (quadrant === 1) return '#3366c7';
    if (quadrant === 2) return '#ad5e62';
    if (quadrant === 3) return '#f7b05e';
    if (quadrant === 4) return '#75a2a2';
  }

  renderTooltip = (xScale, yScale) => {
    const { current } = this.state;
    const { xTitle, yTitle, size: { width } } = this.props;
    const x = xScale(current.x) - 100;
    const y = yScale(current.y) - 75;
    return (
      <foreignObject x={x - 25 < 0 ? 0 : x} y={y} width={400} height={100} pointerEvents={'none'}>
        <Tooltip>
          <span style={{ fontWeight: 'bold' }}>{current.label}</span>
          <span>{xTitle}: {format('.2f')(current.x)}</span>
          <span>{yTitle}: {format('.2f')(current.y)}</span>
        </Tooltip>
      </foreignObject>
    )
  }

  render() {
    const { xTitle, yTitle, data: { elements, xSeparator, ySeparator }, height, margin, size: { width }} = this.props;
    const { current } = this.state;
    const xScale = this.xScale;
    const yScale = this.yScale;
    return (
      <div>
        <svg height={height} width={width} overflow="initial">
          <LinearGridX scale={xScale} tx={margin.left} ty={margin.top} size={this.height} width={width} />
          <LinearGridY scale={yScale} tx={margin.left} ty={margin.top} size={this.width} />
          <g className='linear-grid grid' transform={`translate(${30}, ${margin.top})`}>
            <text textAnchor='middle' x={-this.height / 2} transform='rotate(-90)'>{yTitle}</text>
          </g>
          <g className='linear-grid grid' transform={`translate(${margin.left}, ${height - 20})`}>
            <text textAnchor='middle' x={this.width / 2}>{xTitle}</text>
          </g>
          <g className='data' transform={`translate(${margin.left}, ${margin.top})`} >
            <line strokeWidth={2} stroke='#9bb0e1' x1={xScale(xSeparator)} y1={0}  x2={xScale(xSeparator)} y2={this.height}/>
          </g>
          <g className='data' transform={`translate(${margin.left}, ${margin.top})`} >
            <line strokeWidth={2} stroke='#9bb0e1' x1={0} y1={yScale(ySeparator)}  x2={this.width} y2={yScale(ySeparator)}/>
          </g>
          <g className='data' transform={`translate(${margin.left}, ${margin.top})`} >

            {elements.map((datapoint, index) => {
              return <circle
                key={index}
                fill={this.getCircleFill(datapoint)}
                onMouseEnter={e => this.handleMouseEnter(e, datapoint)}
                cx={xScale(datapoint.x)}
                cy={yScale(datapoint.y)}
                stroke='#fff'
                r={7}
              />
            })}
            {current ? this.renderTooltip(xScale, yScale) : null}
          </g>
        </svg>
      </div>
    )
  }
}

Scatter.defaultProps = {
  height: 500,
  data: {
    elements: [],
    xSeparator: 0,
    ySeparator: 0
  },
  margin: {
    top: 50,
    right: 50,
    bottom: 50,
    left: 80
  },
  size: {
    width: 0
  },
  xTitle: 'X Axis',
  yTitle: 'Y Axis'
}

const LinearGridX = ({ scale, width, tx, ty, size, ...props }) => {
  const tickCount = width < 720 ? 4 : 10;
  const ticks = scale.ticks(tickCount);
  return (
    <g className='linear-grid grid' transform={`translate(${tx}, ${ty})`}>
      {
        ticks.map((d, i) => {
          return (
            <g key={i} transform={`translate(${scale(d)}, 0)`}>
              <line stroke='#002b36' x1={0} y1={0}  x2={0} y2={size} strokeDasharray='2' />
              <text fontSize={10} fill='#212529' textAnchor='middle' y={size}>{d}</text>
            </g>
          );
        })
      }
    </g>
  );
}

const LinearGridY = ({ scale, width, tx, ty, size, ...props }) => {
  const ticks = scale.ticks();
  return (
    <g className='linear-grid grid' transform={`translate(${tx}, ${ty})`}>
      {
        ticks.map((d, i) => {
          return (
            <g key={i} transform={`translate(0, ${scale(d)})`}>
              <line stroke='#002b36' x1={0} y1={0}  x2={size} y2={0} strokeDasharray='2' />
              <text fontSize={10} textAnchor='end' x={-10} fill='#212529'>{d}</text>
            </g>
          );
        })
      }
    </g>
  );
}

export default withSize()(Scatter);
