import styled from "@emotion/styled";
import React from 'react';

const Styled = styled.div`
  background: #323d53;
  padding: 1rem 2rem 2rem 2rem;
  a {
    text-decoration: none;
  }
  h1, span {
    color: #fff;
  }
  h1 {
    font-size: 2rem;
    margin: 0;
  }
  span {
    font-size: 1.5rem;
  }
`

export const PageNavbar = ({ title, subtitle }) => {
  return (
    <Styled>
      <a href="/app?delimitation=nacional&type=panorama">
        <h1>{title}: <span>{subtitle}</span></h1>
      </a>
    </Styled>
  );
}
