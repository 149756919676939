import styled from "@emotion/styled";
import React from 'react';
import { Chart } from 'react-charts';

const Styled = styled.div`
  position: relative;
  span {
    display: block;
    text-align: center;
    margin: 1rem 0;
  }
  .chart-title {
    font-size: 16px;
  }
  .chart-x-axis, .chart-y-axis {
    font-size: 14px;
  }
  .chart-y-axis {
    transform: rotate(-90deg);
    position: absolute;
    top: 50%;
  }
  .chart-context {
    font-size: 10px;
    font-weight: 300;
  }
`;

export class TimeSeries extends React.Component {
  render () {
    const { data, title, yAxisTitle, xAxisTitle, note } = this.props;
    const primaryAxis = {
        getValue: d => d.primary,
        formatters: {
            tooltip: (value) => {
                if (!value) return "";
                let options = { year: 'numeric', month: 'long', day: 'numeric' };
                return value.toLocaleString('es-MX', options);
            },
            scale: (value) => {
                if (!value) return "";
                let options = { month: 'short', day: 'numeric', year: 'numeric' };
                return value.toLocaleString('es-MX', options);
            }
        }
    };
    const secondaryAxes = [{
        getValue: d => d.secondary,

    }];

    const margin = { top: 0, right: 0, bottom: 0, left: 60 };
    return (
        <Styled>
          <span className="chart-title">{title}</span>
          <div style={{ height: 500, marginLeft: margin.left }}>
            <Chart
              options={{
                defaultColors: ['#64d9d5', '#712130'],
                data,
                primaryAxis,
                secondaryAxes,
                  tooltip: (...args) => {
                    console.log('args', args);
                    return null;
                  }
              }}
            />
          </div>
          <span className="chart-y-axis">{xAxisTitle}</span>
          <div style={{ marginLeft: margin.left, marginRight: 20 }}>
            <span className="chart-x-axis">{yAxisTitle}</span>
            <span className="chart-context">{note}</span>
          </div>
        </Styled>
    )
  }
}

TimeSeries.defaultProps = {
  data: [],
  title: 'Default title',
  yAxisTitle: 'Y Axis Title',
  xAxisTitle: 'X Axis Title',
  note: 'Note'
}
